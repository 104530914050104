<template>
  <div id="main-container">
    <div id="background-image"></div>

    <NavBar
      id="navbar"
      :trip-items="trips"
      text-attr="displayName"
      to-attr="id"
      to-prefix="/trips/"
    />

    <div id="main">
      <router-view></router-view>
    </div>
    
  </div>
</template>

<script>
import { firebase } from "./plugins";
import { NavBar } from "./components";

export default {
  name: "App",
  components: {
    NavBar,
  },
  data: () => ({
    trips: []
  }),
  firestore: {
    trips: firebase.tripsRef.orderBy("order")
  }
};
</script>

<style>
/** Roseti Style based on: https://roseti-fluid-demo.squarespace.com/ */
:root {
  --bwb-color: #009290;
  --bwb-color-trans: #00929087;
  --bwb-dark-color: #083b3b;
  --bwb-dark-hover: #006464;
  --footer-color: rgb(10, 65, 75, 80%);
  --a-tag-color: rgb(0, 0, 255);

  --navbar-height: 100px;
  --main-height: calc(100vh - 100px);
  --footer-height: 40px;

  --mobile-navbar-height: 200px;
  --mobile-main-height: calc(100vh - 200px);
  --mobile-footer-height: 80px;

  --main-left-margin: 80px;
  --main-right-margin: 80px;
}

* {
  color: #000000;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  padding: 0px;
  margin: 0px;
}

a {
  text-decoration: none;
}

::-webkit-scrollbar {
  display: none;
}

#main-container {
  min-width: 325px;
}

#background-image {
  position: fixed;
  z-index: -9999;
  width: 100vw;
  height: 100vh;

  background-color: hsla(40, 8%, 92%, 1);
}

#main {
  position: relative;
  margin: 64px 0px 64px 0px;
}

@media (max-width: 1024px) {
  #main {
    margin: 32px 0px 64px 0px;
  }
}

@media (max-width: 800px) {
  :root { 
    --main-left-margin: 20px;
    --main-right-margin: 20px;
  }

  #main {
    margin: 32px 0px 64px 0px;
  }
}
</style>
