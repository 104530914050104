<template>
  <footer id="footer">
    <div style="display: flex; flex-direction: row;">
      <img class="footer-item logo-image" src="@/assets/logo.png" />
      <div class="footer-item middle-footer-item">
        Builders without Borders of Utah
      </div>
    </div>
    <div class="footer-item">
      Questions?
      <br />
      <a class="link" href="mailto:bwbutah@gmail.com" target="_blank">
        bwbutah@gmail.com
      </a>
    </div>
  </footer>
</template>

<script>
export default {};
</script>

<style scoped>
#footer {
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;

  margin-top: 60px;
  padding-left: 10px;

  width: auto;
  height: 60px;

  background-color: rgb(0 144 146 / 10%); /* var(--footer-color); */
}
.middle-footer-item {
  min-width: 180px;
}
.footer-item {
  font-size: 15px;
  text-align: center;
  color: rgba(255, 255, 255, 0.75);
  margin: auto 10px;
  max-height: 40px;
}
.logo-image {
  max-height: 50px;
  max-width: 50px;
  border-radius: 50%;
}

@media (max-width: 800px) {
  #footer {
    height: 40px;
  }
  .footer-item {
    font-size: 12px;
  }
  .logo-image {
    max-height: 40px;
    max-width: 40px;
  }
}
.link {
  color: rgba(255, 255, 255, 0.75);
}
.link:hover {
  color: rgba(255, 255, 255, 0.95);
}
</style>
