var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('nav',{staticClass:"navbar"},[_c('div',{staticClass:"logo-title"},[_c('img',{attrs:{"src":require("@/assets/bwblogo2.png")}}),_c('router-link',{staticClass:"title",attrs:{"to":"/","custom":""}},[_vm._v(" Builders Without Borders of Utah ")])],1),_c('div',{staticClass:"dropdowns"},[_c('div',{staticClass:"dropdown"},[_c('router-link',{attrs:{"to":"/","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('button',{attrs:{"role":"link"},on:{"click":navigate}},[_vm._v("Home")])]}}])})],1),_c('div',{staticClass:"dropdown"},[_c('router-link',{attrs:{"to":"/trips","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('button',{attrs:{"role":"link"},on:{"click":navigate}},[_vm._v("Trips")])]}}])}),_c('div',{staticClass:"dropdown-menu"},[_c('div',[_vm._l((_vm.tripItems),function(item){return [_c('router-link',{key:item[_vm.textAttr],attrs:{"to":_vm.toPrefix + item[_vm.toAttr],"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('button',{attrs:{"role":"link"},domProps:{"textContent":_vm._s(item[_vm.textAttr])},on:{"click":navigate}})]}}],null,true)})]})],2)])],1),_c('div',{staticClass:"dropdown"},[_c('router-link',{attrs:{"to":"/contact","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('button',{attrs:{"role":"link"},on:{"click":navigate}},[_vm._v("Contact")])]}}])})],1),_c('div',{staticClass:"dropdown bg-bwb"},[_c('router-link',{attrs:{"to":"/donate","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('button',{attrs:{"role":"link"},on:{"click":navigate}},[_vm._v("Donate")])]}}])})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }