import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  { path: "*", component: () => import("../views/NotFoundComponent.vue") },
  {
    path: "/",
    name: "Home",
    component: () => import("../views/Home.vue")
  },
  {
    path: "/trips",
    name: "Trips",
    component: () => import("../views/Trips.vue")
  },
  {
    path: "/trips/:id",
    name: "Trip",
    component: () => import("../views/Trip.vue")
  },
  {
    path: "/contact",
    name: "Contact",
    component: () => import("../views/Contact.vue")
  },
  {
    path: "/donate",
    name: "Donate",
    component: () => import("../views/Donate.vue")
  },
  {
    path: "/info",
    name: "Info",
    component: () => import("../views/info/Home.vue")
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  document.title = (to.name ? to.name + " - " : "") + "BWB Utah";
  next();
});

export default router;
