import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import { vuexfireMutations } from "vuexfire";

export default new Vuex.Store({
  mutations: vuexfireMutations
});
