import fb from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyC1NPZOs3xxdTpbN_Tt9kC2M5ef_dfWt8E",
  authDomain: "bwb-f8d57.firebaseapp.com",
  projectId: "bwb-f8d57",
  storageBucket: "bwb-f8d57.appspot.com",
  messagingSenderId: "850588089864",
  appId: "1:850588089864:web:191b90d31d15c98f011bbd",
  measurementId: "G-G0KNYFPXQ9"
};

// Initialize Firebase
fb.initializeApp(firebaseConfig);

// utils
const db = fb.firestore();
const auth = fb.auth();

// collection references
const usersRef = db.collection("users-new");
const varsRef = db.collection("vars-new");
const tripsRef = db.collection("trips-new");
const boardRef = db.collection("board-new");
const pagesRef = varsRef.doc("site").collection("pages");
const keysRef = db.doc("stripe/keys");

// Helpful Methods -> Ref
const userRef = () => usersRef.doc(auth.currentUser.uid);
const projectRef = projectId =>
  userRef()
    .collection("Projects")
    .doc(projectId);
const tripRef = id => tripsRef.doc(id);
const tripData = (id, callback) =>
  tripsRef
    .doc(id)
    .get()
    .then(callback);

// async function asdf(outputRef, inputRef) {
//   console.log("asdf", (await outputRef.get()).docs)

//   for (var doc of (await outputRef.get()).docs) {
//     console.log(doc.id, doc.data())
//     inputRef.doc(doc.id).set(doc.data())
//   }
// }
// asdf(pagesRef, db.collection("vars-new").doc("site").collection("pages"));

const { Timestamp, GeoPoint } = fb.firestore;

// export utils/refs
export default {
  fb,
  db,
  auth,
  usersRef,
  userRef,
  varsRef,
  tripsRef,
  tripRef,
  tripData,
  boardRef,
  pagesRef,
  keysRef,
  projectRef,
  Timestamp,
  GeoPoint
};
