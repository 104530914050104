<template>
  <div>
    <div class="venmo-container">
      <!-- https://venmo.com/<USER_NAME_1>,<USER_NAME_2>...?txn=<charge|pay>&note=<NOTE>&amount=<AMOUNT> -->
      <a
        :href="
          `https://venmo.com/${trip.donate.venmo_tag}?txn=pay&note=${payment_note}`
        "
        target="_blank"
      >
        <v-img
          class="venmo-qrcode"
          src="@/assets/venmo_qr_codes/bwobutah.jpg"
        />

        <div id="venmo-button">
          <img id="venmo-button-image" src="/svgs/venmo.png" />
          <span
            id="venmo-button-text"
            v-text="'@' + trip.donate.venmo_tag"
          ></span>
        </div>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  props: ["trip"],
  data: () => ({
    payment_note: "Donation to Builders without Borders of Utah"
  })
};
</script>

<style scoped>
.venmo-container {
  max-width: 180px;
  color: white;
}

.venmo-container:hover {
  /* box-shadow: 2px 2px 2px 1px #008cff8e; */
}

.venmo-qrcode {
  /* border: solid 2px #008cff; */
}

#venmo-button {
  display: flex;
  column-gap: 12px;
  max-height: 50px;
  margin-top: 20px;
}
#venmo-button:hover {
  opacity: 0.8;
}

#venmo-button-image {
  max-height: 50px;
  border-radius: 1400px;
}

#venmo-button-text {
  color: white;
  margin: auto;
  margin-left: 0px;
  font: bold italic 20px sans-serif;
  text-decoration: underline;
}
</style>
