<template>
  <nav class="navbar">
    
    <div class="logo-title">
      <img src="@/assets/bwblogo2.png" />

      <router-link to="/" custom class="title">
        Builders Without Borders of Utah
      </router-link>
    </div>

    <div class="dropdowns">
      <div class="dropdown">
        <router-link to="/" custom v-slot="{ navigate }">
          <button @click="navigate" role="link">Home</button>
        </router-link>
      </div>

      <div class="dropdown">
        <router-link to="/trips" custom v-slot="{ navigate }">
          <button @click="navigate" role="link">Trips</button>
        </router-link>

        <div class="dropdown-menu">
          <div>
            <template v-for="item in tripItems">
              <router-link
                :key="item[textAttr]"
                :to="toPrefix + item[toAttr]"
                custom
                v-slot="{ navigate }"
              >
                <button
                  @click="navigate"
                  role="link"
                  v-text="item[textAttr]"
                ></button>
              </router-link>
            </template>
          </div>
        </div>
      </div>

      <div class="dropdown">
        <router-link to="/contact" custom v-slot="{ navigate }">
          <button @click="navigate" role="link">Contact</button>
        </router-link>
      </div>

      <div class="dropdown bg-bwb">
        <router-link to="/donate" custom v-slot="{ navigate }">
          <button @click="navigate" role="link">Donate</button>
        </router-link>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  props: {
    tripItems: [Array, Object],
    textAttr: String,
    toAttr: String,
    toPrefix: {
      type: [String, Object],
      default: val => val
    },
    onClick: {
      type: Function,
      default: () => undefined
    }
  }
};
</script>

<style scoped>
.navbar {
  background: transparent;
  color: #f9f9f9;
}

.navbar {
  z-index: 1;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 216px;
  margin: 0px var(--main-right-margin) 0px var(--main-left-margin);
}

@media (min-width: 1024px) {
  .navbar {
    flex-direction: row;
    --nav-padding-top: 36px;
    height: calc(72px + var(--nav-padding-top));
    padding-top: var(--nav-padding-top);
  }
}

.logo-title {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  margin: auto 0px;

  gap: 16px;
}
.logo-title > img {
  height: 72px;
}
.title {
  font-size: 32px;
  text-align: center;
  margin: auto;
  font-weight: bold;
}

button {
  border: 0;
  padding: 0;
  display: flex;
  align-items: center;
  gap: 4px;
  height: 100%;
  font-family: inherit;
  background: transparent;
  color: inherit;
  cursor: pointer;
  font-size: 14px;
}

@media (min-width: 300px) {
  .title {
    font-size: 24px;
  }
}

@media (min-width: 500px) {
  .title {
    font-size: 40px;
  }
  button {
    font-size: 16px;
  }
}

.bg-bwb {
  background-color: var(--footer-color);
  color: white;
}

button > img {
  display: none;
}

.dropdown > button {
  padding: 0px 24px;
}

@media (min-width: 500px) {
  button > img {
    display: block;
  }
}

.dropdowns {
  position: static;
  left: -9999px;
  z-index: 2;
  height: 72px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding-left: 0px;
  background: transparent;
  opacity: 1;
  visibility: visible;
  transition-property: opacity, visibility;
  transition-duration: 0.3s;
}

.dropdown-menu {
  display: none;
}

.dropdown-menu button {
  display: block;
  width: 120px;
  color: #f9f9f9;
  opacity: 1;
  height: 48px;
  white-space: nowrap;
}

.dropdown-menu button:hover {
  background-color: #ffffff1c;
}

.dropdown {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  height: 100%;
  flex-direction: row;
  align-items: center;
}

@media (min-width: 500px) {
  .dropdown-menu {
    display: block;
    position: absolute;
    top: 72px;
    left: 0;
    display: flex;
    gap: 32px;
    margin-bottom: 0;
    justify-content: flex-start;
    background-color: var(--footer-color);
    opacity: 0;
    visibility: hidden;
    translate: 0 24px;
    transition: 0.3s;
  }

  .dropdown:hover {
    background-color: var(--footer-color);
    color: white;
  }

  .dropdown:hover .dropdown-menu {
    opacity: 1;
    visibility: visible;
    translate: 0 0;
  }


}
</style>
